<template>
    <div class="fb-pages-modal">
        <!-- modal vertical center -->
        <b-modal id="modal-center" scrollable centered size="lg" title="Connect Telegram Channel Or Group" ok-only
            ok-title="Accept" v-model="showModal" no-close-on-backdrop no-close-on-esc @ok="connectPage">
            <b-overlay :show="showLoader" rounded="sm" spinner-variant="primary" variant="transparent">
                <b-row>
                    <b-col cols="7">
                        <b-alert variant="info" show>
                            <h4 class="alert-heading">
                                Connect Telegram Channel or Group
                            </h4>
                            <div class="alert-body">
                                <p>Go to Your telegram channel on info section</p>
                                <p>Go to Your telegram channel on info section</p>
                                <p>Go to Your telegram channel on info section</p>
                                <p>Go to Your telegram channel on info section</p>
                                <p>
                                    Lorem ipsum dolor sit amet
                                    <b-link class="alert-link">
                                        learn more
                                    </b-link>
                                    dllkd
                                </p>
                            </div>
                        </b-alert>

                        <validation-observer ref="simpleRules">
                            <!-- form -->
                            <b-form class="mt-2" @submit.prevent="connectPage">
                                <b-row>
                                    <b-col md="12">
                                        <b-form-group label="Invite Link (Or Username)" label-for="blog-edit-title" class="mb-2">
                                            <validation-provider #default="{ errors }" name="Invite Link (Or Username)" rules="required">
                                                <b-form-input id="blog-edit-title" placeholder="@yourchannel or https://w.me/dij98d9"
                                                    :state="errors.length > 0 ? false : null" v-model="pageForm.channel_link" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </b-col>
                    <b-col cols="5">
                        Vieo here
                    </b-col>
                </b-row>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize, } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    BModal, BButton, VBModal, BCardText, BAvatar, BRow, BCol, BMedia, BFormRadio, BLink, BCard, BOverlay, BSpinner, BAlert, BForm, 
    BFormGroup, BFormInput
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import axios from 'axios'
export default {
    components: {
        ToastificationContent, ValidationProvider, ValidationObserver,
        BButton, BModal, BCardText, BAvatar, BRow, BCol, BMedia, BFormRadio, BLink, BCard, BOverlay, BSpinner, BAlert, BForm, 
        BFormGroup, BFormInput
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    data() {
        return {
            showLoader: true,
            pages: [],
            showModal: false,
            pageForm: {
                channel_link: null,
                brand: 0
            },
            required
        }
    },
    methods: {
        setShowModalTrue() {
            this.showModal = true
            this.showLoader = false
        },
        connectPage(bvModalEvt) {
            this.showLoader = true
            bvModalEvt.preventDefault()
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.pageForm.brand = this.$store.state.auth.user.current_brand
                    axios.post("/channels/telegram_connect/", this.pageForm)
                        .then(response => {
                            this.showLoader = false
                            console.log(response);
                            this.showModal = false
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Channel Connected',
                                    icon: 'CheckIcon',
                                    text: 'I do not think that word means what you think it means.',
                                    variant: 'success',
                                },
                            })
                            this.pageForm.channel_link = ''
                            this.$emit('channelConnected')
                        })
                        .catch(error => {
                            this.showLoader = false
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Warning !',
                                    icon: 'AlertCircleIcon',
                                    text: 'I do not think that word means what you think it means.',
                                    variant: 'success',
                                },
                            })
                            console.log(error);
                        })
                } else {
                    this.showLoader = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Warning !',
                            icon: 'AlertCircleIcon',
                            text: 'Please enter link or username.',
                            variant: 'danger',
                        },
                    })
                }
            })
        }
    }
}
</script>
<style>
.popular {
    border: 1px solid #7367f0;
}
</style>