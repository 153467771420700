export const FACEBOOK_PAGE_REDIRECT_URL = process.env.VUE_APP_FACEBOOK_PAGE_REDIRECT_URL

export const INSTAGRAM_REDIRECT_URL = process.env.VUE_APP_INSTAGRAM_REDIRECT_URL

export const TWITTER_REDIRECT_URL = ""

export const LINKEDIN_PERSONAL_REDIRECT_URL = process.env.VUE_APP_LINKEDIN_PERSONAL_REDIRECT_URL

export const LINKEDIN_COMPANY_REDIRECT_URL = process.env.VUE_APP_LINKEDIN_COMPANY_REDIRECT_URL

export const TIKTOK_REDIRECT_URL = process.env.VUE_APP_TIKTOK_REDIRECT_URL

export const REDDIT_REDIRECT_URL = process.env.VUE_APP_REDDIT_REDIRECT_URL

export const YOUTUBE_REDIRECT_URL = process.env.VUE_APP_YOUTUBE_REDIRECT_URL

export const GMB_REDIRECT_URL = ""

export const PINTEREST_REDIRECT_URL = process.env.VUE_APP_PINTEREST_REDIRECT_URL