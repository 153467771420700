<template>
    <!-- pricing free trial -->
    <div class="pricing-free-trial">
        <b-row>
            <b-col
                lg="10"
                offset-lg="3"
                class="mx-auto"
            >
                <div class="pricing-trial-content d-flex justify-content-between">
                    <div class="text-center text-md-left mt-3">
                        <h3 class="text-primary">
                            Start connecting your first channel !
                        </h3>
                        <h5>You will get full access to your channel including post, comment and content management.</h5>
                        <b-button
                            variant="primary"
                            class="mt-2 mt-lg-3"
                            @click="$emit('showConnectChannelModal')"
                        >
                            Connect FIRST channel
                        </b-button>
                    </div>

                    <!-- images -->
                    <b-img
                        fluid
                        :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
                        class="pricing-trial-img"
                        alt="svg img"
                    />
                    <!--/ images -->
                </div>
            </b-col>
        </b-row>
    </div>
    <!--/ pricing free trial -->
</template>

<script>
import { BRow, BCol, BImg, BButton} from 'bootstrap-vue'
export default {
    components: {BRow, BCol, BImg, BButton}
}
</script>