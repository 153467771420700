<template>
    <div class="channels">
        <b-row class="justify-content-md-center">
            <b-col cols="8">
                <b-card no-body>
                    <b-card-header>
                        <b-card-title>Connected Channels</b-card-title>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="primary"
                            class="btn-icon ml-auto"
                            @click="showChannelModal = true"
                        >
                            Connect New Channel
                            <feather-icon icon="PlusIcon" />
                        </b-button>
                    </b-card-header>
                    
                    <b-overlay
                        :show="showLoader"
                        variant="transperant"
                        opacity="1" blur="5px"
                        spinner-variant="primary"
                    >
                        <b-card-body>

                            <!-- user suggestion  -->
                            <div
                                class="d-flex justify-content-start align-items-center"
                                v-for="channel, index in channels"
                                :key="channel.id"
                                :class="index == 0 ? '' : 'mt-2'"
                            >
                                <b-avatar
                                    :src="channel.image_url"
                                    class="mr-50"
                                    size="50"
                                    badge-variant="default"
                                >
                                    <template #badge>
                                        <b-avatar size="15" :src="getChannelTypeImage(channel.type)" variant="default" />
                                    </template>
                                </b-avatar>
                                <div class="user-page-info">
                                    <h6 class="mb-0">
                                        {{ channel.title }}
                                    </h6>
                                    <small class="text-muted">{{ channel.username ? "@"+channel.username : "" }}</small>
                                </div>
                                <div class="ml-auto">
                                    <b-button
                                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                        variant="default"
                                        class="btn-icon mr-1"
                                        size="sm"
                                        v-b-tooltip.hover.v-primary
                                        title="reconnect"
                                        @click="reconnect(channel)"
                                    >
                                        <feather-icon icon="RefreshCcwIcon" />
                                    </b-button>
                                    <b-button
                                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                        variant="danger"
                                        class="btn-icon"
                                        size="sm"
                                        v-b-tooltip.hover.v-danger
                                        title="delete"
                                        @click="deleteChannel(channel.id)"
                                    >
                                        <feather-icon icon="TrashIcon" />
                                    </b-button>
                                </div>
                            </div>
                            <!--/ user suggestion  -->

                            <template v-if="channels.length == 0">
                                <get-start @showConnectChannelModal="showConnectChannelModal" />
                            </template>
                        </b-card-body>
                    </b-overlay>
                </b-card>
            </b-col>
        </b-row>

        <b-modal
            id="modal-lg"
            v-model="showChannelModal"
            ok-only
            ok-title="Cancel"
            ok-variant="secondary"
            centered
            size="lg"
            title="Choose Channels"
        >
            <div
                id="icons-container"
                class="d-flex flex-wrap"
            >
                <b-row>
                    <b-col cols="4" v-for="channel in baseChannels" :key="channel.name">
                        <b-card
                            class="icon-card cursor-pointer text-center mb-2 mx-50" :class="{'popular': hoverChannel==channel.type}"
                            @click="processChannel(channel)" @mouseenter="hoverChannel=channel.type" @mouseleave="hoverChannel=null"
                        >

                            <div class="icon-wrapper">
                                <!-- <b-avatar
                                    size="70px"
                                    :src="channel.image"
                                /> -->
                                <b-img :src="channel.image" :width="channelImageWidth(channel.name)" height="30" fluid  />
                            </div>
                            <b-card-text class="icon-name text-truncate mb-0 mt-1">{{channel.name}}</b-card-text>
                            <small class="text-muted">{{ channel.description }}</small>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
        <facebook-pages @channelConnected="getChannels" ref="facebookPagesModal" />
        <instagram-pages @channelConnected="getChannels" ref="instagramPagesModal" />
        <linkedin-organizations @channelConnected="getChannels" ref="linkedinOrganizationsModal" />
        <pinterest-boards @channelConnected="getChannels" ref="PinterestBoardsModal" />
        <telegram @channelConnected="getChannels" ref="TelegramComponent" />
        <RedditSubreddits @channelConnected="getChannels" ref="RedditSubreddits" />
    </div>
</template>


<script>
import FacebookPages from './modal-components/FacebookPages.vue'
import InstagramPages from './modal-components/InstagramPages.vue'
import LinkedinOrganizations from './modal-components/LinkedinOrganizations.vue'
import PinterestBoards from './modal-components/PinterestBoards.vue'
import RedditSubreddits from './modal-components/RedditSubreddits.vue'
import Telegram from './modal-components/Telegram.vue'

import GetStart from './modal-components/GetStart.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BAvatar, BButton, BRow, BCol, BCardHeader, BCardTitle, BCardBody, BModal, BCardText, BLink, BImg, VBTooltip, BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

import baseChannels from '@/constants/channels'
export default {
    components: {
        FacebookPages, InstagramPages, ToastificationContent, GetStart, LinkedinOrganizations, PinterestBoards, RedditSubreddits, Telegram,
        BCard, BAvatar, BButton, BRow, BCol, BCardHeader, BCardTitle, BCardBody, BModal, BCardText, BLink, BImg, VBTooltip, BOverlay
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data(){
        return {
            hoverChannel: null,
            showLoader: true,
            showChannelModal: false,
            channels: [],
            baseChannels: baseChannels,
            facebook_code: ""
        }
    },
    mounted(){
        if (this.$route.query.state) {
            this.showLoader = true
            if (this.$route.query.state == "fb_page") {
                this.$refs.facebookPagesModal.getPages(this.$route.query.code);
            }
            if (this.$route.query.state == "instagram") {
                this.$refs.instagramPagesModal.getPages(this.$route.query.code);
            }

            if (this.$route.query.state == "pinterest") {
                this.$refs.PinterestBoardsModal.getBoards(this.$route.query.code);
            }

            if (this.$route.query.state == "linkedin_company") {
                this.$refs.linkedinOrganizationsModal.getPages(this.$route.query.code);
            }
            if (this.$route.query.state == "linkedin_personal") {
                this.connectChannel("/channels/linkedin_connect/", "linkedin_personal", this.$route.query.code)
            }

            if (this.$route.query.state == "reddit") {
                // this.connectChannel("/channels/reddit_connect/", "reddit", this.$route.query.code)
                this.$refs.RedditSubreddits.getPages(this.$route.query.code);
            }

            if (this.$route.query.state == "google_youtube") {
                this.connectChannel("/channels/youtube_connect/", "google_youtube", this.$route.query.code)
            }

            if (this.$route.query.state == "tiktok") {
                this.connectChannel("/channels/tiktok_connect/", "tiktok", this.$route.query.code)
            }
        }

        if(this.$route.query.oauth_verifier) {
            this.connectChannel("/channels/twitter_connect/", "twitter", this.$route.query.oauth_verifier)
        }

        // denied case
        if (this.$route.query.denied){
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Connection failed !',
                    icon: 'AlertCircleIcon',
                    text: 'I do not think that word means what you think it means.',
                    variant: 'danger',
                },
            })
            this.$router.replace({'query': null})
        }

        if (!this.$route.query.oauth_verifier && !this.$route.query.state) {
            this.getChannels()
        }
    },
    methods: {
        showConnectChannelModal(){
            this.showChannelModal = true
        },
        getChannelTypeImage(type){
            if (type == "fb_page") return require('@/assets/images/channels/facebook.png')
            if (type == "instagram") return require('@/assets/images/channels/instagram.png')
            if (type == "twitter") return require('@/assets/images/channels/twitter-circle.png')
            if (type == "tiktok") return require('@/assets/images/channels/tiktok-circle.png')
            if (type == "linkedin_personal") return require('@/assets/images/channels/linkedin.png')
            if (type == "linkedin_company") return require('@/assets/images/channels/linkedin.png')
            if (type == "google_youtube") return require('@/assets/images/channels/youtube.png')
            if (type == "reddit") return require('@/assets/images/channels/reddit.png')
            if (type == "pinterest") return require('@/assets/images/channels/pinterest-circle.png')
            if (type == "google_business") return require('@/assets/images/channels/gmb.svg')
            if (type == "telegram") return require('@/assets/images/channels/telegram.png')
        },
        reconnect(channel){
            this.baseChannels.forEach(item => {
                if (item.type ==channel.type){
                    this.processChannel(item)
                }
            })
        },
        async processChannel(channel){
            await axios.post('/channels/check_quota/')
            .then(res => {
                console.log(res);
                if (channel.type == 'twitter') {
                    axios.get("/channels/twitter_init/")
                    .then(response => {
                        location.href = response.data.auth_url
                    })
                    .catch(error => {
                        console.log(error);
                    })
                } else if (channel.type == 'telegram') {
                    this.showChannelModal = false
                    this.$refs.TelegramComponent.setShowModalTrue();
                } else {
                    location.href = channel.url
                }
            })
            .catch(error => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Upgrade Plan!',
                        icon: 'AlertCircleIcon',
                        text: 'Channels quota exceeded, please upgrade plan.',
                        variant: 'danger',
                    },
                })
                console.log(error);
            })
        },
        async connectChannel(channel_path, channel_type, code){
            this.showLoader = true
            var data;
            if (channel_type == "twitter") {
                data = {
                    oauth_verifier: code,
                    brand_id: this.$store.state.auth.user.current_brand
                }
            } else {
                data = {
                    code: code,
                    brand_id: this.$store.state.auth.user.current_brand
                }
            }

            await axios.post(channel_path, data)
            .then(response => {
                this.showLoader = false
                console.log(response);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Channel Connected',
                        icon: 'CheckIcon',
                        text: 'I do not think that word means what you think it means.',
                        variant: 'success',
                    },
                })
                this.getChannels()
                this.$router.replace({'query': null})
            })
            .catch(error => {
                this.showLoader = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Connection failed !',
                        icon: 'AlertCircleIcon',
                        text: 'I do not think that word means what you think it means.',
                        variant: 'danger',
                    },
                })
                this.$router.replace({'query': null})
                console.log(error);
            })
        },
        channelImageWidth(name){
            if (name == "Youtube") {
                return 60
            }
            if (name == "Twitter") {
                return 50
            }
            return 40
        },
        deleteChannel(id){
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    axios.delete(`/channels/${id}/`)
                    .then(response => {
                        console.log(response);
                        this.$swal({
                            icon: 'success',
                            title: 'Deleted!',
                            text: 'Your Channel has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.getChannels()
                    })
                    .catch(error => {
                        console.log(error);
                    })
                }
            })
        },
        async getChannels(){
            this.showLoader = true
            await axios.get(`/channels?brand_id=${this.$store.state.auth.user.current_brand}`)
            .then(response => {
                this.channels = response.data
                this.showLoader = false
                // setTimeout(() => { this.showLoader = false }, 800);
            })
            .catch(error => {
                this.showLoader = false
                console.log(error);
            })
        }
    }
}
</script>
<style>
  .popular { border: 1px solid var(--primary); }
</style>