import {
    FACEBOOK_PAGE_REDIRECT_URL, INSTAGRAM_REDIRECT_URL, TWITTER_REDIRECT_URL, LINKEDIN_PERSONAL_REDIRECT_URL,
    LINKEDIN_COMPANY_REDIRECT_URL, TIKTOK_REDIRECT_URL, REDDIT_REDIRECT_URL, YOUTUBE_REDIRECT_URL, PINTEREST_REDIRECT_URL ,
    GMB_REDIRECT_URL
} from './redirect_urls'

export default [
    {
        name: "Facebook Page",
        type: "fb_page",
        description: "Connect your facebook page.",
        image: require('@/assets/images/channels/facebook.png'),
        url: FACEBOOK_PAGE_REDIRECT_URL
    },
    {
        name: "Instagram",
        type: "instagram",
        description: "Connect your Instagram bussiness account.",
        image: require('@/assets/images/channels/instagram.png'),
        url: INSTAGRAM_REDIRECT_URL
    },
    {
        name: "Twitter",
        type: "twitter",
        description: "Connect your Twitter account.",
        image: require('@/assets/images/channels/twitter.png'),
        url: TWITTER_REDIRECT_URL
    },
    {
        name: "Tiktok",
        type: "tiktok",
        description: "Connect your Tiktok account.",
        image: require('@/assets/images/channels/tiktok.png'),
        url: TIKTOK_REDIRECT_URL
    },
    {
        name: "Linkedin Personal",
        type: "linkedin_personal",
        description: "Connect your Linkedin Profile account.",
        image: require('@/assets/images/channels/linkedin.png'),
        url: LINKEDIN_PERSONAL_REDIRECT_URL
    },
    {
        name: "Linkedin Company",
        type: "linkedin_company",
        description: "Connect your Linkedin Company account.",
        image: require('@/assets/images/channels/linkedin.png'),
        url: LINKEDIN_COMPANY_REDIRECT_URL
    },
    {
        name: "Youtube",
        type: "google_youtube",
        description: "Connect your Youtube channel.",
        image: require('@/assets/images/channels/youtube.png'),
        url: YOUTUBE_REDIRECT_URL
    },
    {
        name: "Telegran",
        type: "telegram",
        description: "Connect your Telegram Public channel or group.",
        image: require('@/assets/images/channels/telegram.png'),
        url: YOUTUBE_REDIRECT_URL
    },
    {
        name: "Reddit",
        type: "reddit",
        description: "Connect your Reddit account",
        image: require('@/assets/images/channels/reddit.png'),
        url: REDDIT_REDIRECT_URL
    },
    {
        name: "Pinterest",
        type: "pinterest",
        description: "Connect your Pinterest board",
        image: require('@/assets/images/channels/pinterest.png'),
        url: PINTEREST_REDIRECT_URL
    },
    {
        name: "Google My Business",
        type: "google_business",
        description: "Connect your Google My Business account.",
        image: require('@/assets/images/channels/gmb.svg'),
        url: GMB_REDIRECT_URL
    },
]