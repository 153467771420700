<template>
    <div class="fb-pages-modal">
        <!-- modal vertical center -->
        <b-modal
            id="modal-center"
            scrollable
            title="Facebook Pages"
            ok-only
            ok-title="Connect"
            v-model="showModal"
            no-close-on-backdrop
            no-close-on-esc
            @ok="connectPage"
        >
        <b-overlay
            :show="showLoader"
            rounded="sm"
            spinner-variant="primary"
            variant="transparent"
        >
            <b-card no-body class="p-1 mb-1" v-for="page in pages" :key="page.id" :class="pageForm.page_id == page.id ? 'popular':''">
                <b-link @click="selectPage(page)">
                    <div
                        class="d-flex justify-content-start align-items-center"
                    >
                        <b-avatar
                            :src="page.picture.data.url"
                            class="mr-50"
                            size="lg"
                        />
                        <div class="user-page-info">
                            <h6 class="mb-0">
                                {{page.name}}
                            </h6>
                            <!-- <small class="text-muted">@hjd</small> -->
                        </div>
                        <b-form-radio
                            name="some-radios"
                            :value="page.id"
                            class="ml-auto"
                            v-model="pageForm.page_id"
                        />
                    </div>
                </b-link>
            </b-card>
        </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { 
    BModal, BButton, VBModal, BCardText, BAvatar, BRow, BCol, BMedia, BFormRadio, BLink, BCard, BOverlay, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
export default {
    components: {
        ToastificationContent,
        BButton, BModal, BCardText, BAvatar, BRow, BCol, BMedia, BFormRadio, BLink, BCard, BOverlay, BSpinner
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    data(){
        return {
            showLoader: true,
            pages: [],
            showModal: false,
            pageForm: {
                page_id: null,
                insta_id: null,
                brand: 0
            }
        }
    },
    methods: {
        selectPage(page){
            this.pageForm.page_id = page.id
            this.pageForm.insta_id = page.instagram_business_account.id
        },
        getPages(code){
            this.showModal = true
            axios.post("/channels/insta_account_pages/", {code: code})
            .then(response => {
                console.log(response);
                this.pages = []
                response.data.result.data.forEach(item => {
                    if (item.instagram_business_account) this.pages.push(item)
                })
                setTimeout(() => { this.showLoader = false }, 600);
            })
            .catch(error => {
                this.$router.replace({'query': null})
                console.log(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Connection failed !',
                        icon: 'AlertCircleIcon',
                        text: 'I do not think that word means what you think it means.',
                        variant: 'danger',
                    },
                })
                this.showModal = false
            })
        },
        connectPage(bvModalEvt){
            bvModalEvt.preventDefault()
            if (this.pageForm.page_id) {
                this.pageForm.brand = this.$store.state.auth.user.current_brand
                axios.post("/channels/insta_connect_page/", this.pageForm)
                .then(response => {
                    this.showModal = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Channel Connected',
                            icon: 'CheckIcon',
                            text: 'I do not think that word means what you think it means.',
                            variant: 'success',
                        },
                    })
                    this.$emit('channelConnected')
                    this.$router.replace({'query': null})
                })
                .catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Warning !',
                            icon: 'AlertCircleIcon',
                            text: 'I do not think that word means what you think it means.',
                            variant: 'danger',
                        },
                    })
                    console.log(error);
                    this.$router.replace({'query': null})
                })
            } else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Warning !',
                        icon: 'AlertCircleIcon',
                        text: 'Please select a page.',
                        variant: 'danger',
                    },
                })
            }
        }
    }
}
</script>
<style>
  .popular { border: 1px solid #7367f0; }
</style>